<template>
  <canvas class="graph" :style="{ width, height }"></canvas>
</template>

<script>
export default {
  name: "v-graph",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "400px",
    },
    scheme: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ctx: null,
      points: {
        counter: 0,
        begin: [],
        end: [],
      },
    };
  },
  computed: {
    config: function () {
      return Object.assign({}, this.defaults(), this.scheme);
    },
  },
  mounted() {
    this.ctx = this.initContext(this.$el);
    this.fit();
    this.run();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    initContext(element) {
      try {
        const ctx = element.getContext("2d");
        ctx.save();
        return ctx;
      } catch (e) {
        console.log(`Couldn\`t init context: ${e}`);
      }
    },
    run() {
      this.start = performance.now();
      this.fit();
      this.preparePointsCollection();
      this.animate();
      return this;
    },
    defaults() {
      return {
        scatter: 0.5,
        angle: 5,
        offset: 0.2,
        background: {
          start: "rgba(94, 209, 189, 0.2)",
          stop: "rgba(94, 209, 189, 0)",
        },
        points: {
          count: 5,
          background: "rgba(214, 244, 237, 1)",
          border: {
            width: 4,
            color: "rgba(174, 231, 219, 1)",
          },
          radius: 6,
        },
        line: {
          width: 2,
          color: "rgba(174, 231, 219, 1)",
        },
        animation: {
          duration: 500,
          delay: 2000,
        },
      };
    },
    draw(timing) {
      const canvas = this.ctx.canvas;
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.flipCanvas();
      const points = [];
      this.points.begin.map((data, i) => {
        const length = this.points.end[i][1] - this.points.begin[i][1];
        const x = this.points.begin[i][0];
        const y = this.points.begin[i][1] + length * timing;
        points.push([x, y]);
      });
      this.drawShape(points);
      points.map((data, i) => {
        if (typeof points[i - 1] !== "undefined") {
          this.drawLine(...points[i - 1], ...data);
        }
      });
      points.map((data, i) => {
        if (i > 0 && i < points.length - 1) {
          this.drawCircle(...data);
        }
      });
    },
    animate(time) {
      time = time ?? performance.now();
      this.fraction = (time - this.start) / this.config.animation.duration;
      this.fraction = this.fraction > 1 ? 1 : this.fraction;
      const easing = this.getEasing(this.fraction);
      this.draw(easing);
      if (this.fraction < 1) {
        requestAnimationFrame((time) => this.animate(time));
      } else {
        setTimeout(() => this.run(), this.config.animation.delay || 1000);
      }
    },
    fit() {
      const el = this.ctx.canvas;
      el.width = el.clientWidth;
      el.height = el.clientHeight;
      return this;
    },
    onResize() {
      this.fit();
      this.preparePointsCollection();
      const easing = this.getEasing(this.fraction);
      this.draw(easing);
    },
    flipCanvas() {
      this.ctx.setTransform(1, 0, 0, -1, 0, this.ctx.canvas.height);
      return this;
    },
    calculatePoints(negative = false) {
      const result = [];
      const canvas = this.ctx.canvas;
      const x0 = 0;
      const y0 = Math.round(canvas.height * this.config.offset);
      result.push([x0, y0]);
      const width = canvas.width;
      const delta = width / (this.config.points.count + 1);
      for (let i = 1; i < this.config.points.count + 2; i++) {
        const x = x0 + delta * i;
        const s =
          i < this.config.points.count + 1
            ? this.calculateScatter() * (i % 2 ? 1 : -1) * (negative ? -1 : 1)
            : 0;
        const y =
          y0 +
          s +
          Math.round(delta * i * Math.tan((this.config.angle * Math.PI) / 180));
        result.push([x, y]);
      }
      return result;
    },
    preparePointsCollection() {
      const counter = ++this.points.counter;
      const negative = counter % 2;
      this.points = {
        counter,
        begin: [
          ...(this.points.begin.length
            ? this.points.end
            : this.calculatePoints(negative)),
        ],
        end: this.calculatePoints(!negative),
      };
      return this;
    },
    drawLine(x0, y0, x1, y1) {
      const ctx = this.ctx;
      ctx.restore();
      ctx.beginPath();
      ctx.moveTo(x0, y0);
      ctx.lineTo(x1, y1);
      ctx.lineWidth = this.config.line.width;
      ctx.strokeStyle = this.config.line.color;
      ctx.stroke();
      return this;
    },
    drawShape(points) {
      const ctx = this.ctx;
      ctx.restore();
      ctx.beginPath();
      ctx.moveTo(points[0][0], 0);
      let start = 0;
      let stop = 0;
      points.map((data) => {
        ctx.lineTo(...data);
        start = Math.max(start, data[1]);
        stop = Math.min(stop, data[1]);
      });
      ctx.lineTo(points[points.length - 1][0], 0);
      ctx.closePath();
      const gradient = ctx.createLinearGradient(0, start, 0, stop);
      gradient.addColorStop(0, this.config.background.start);
      gradient.addColorStop(1, this.config.background.stop);
      ctx.fillStyle = gradient;
      ctx.fill();
      return this;
    },
    drawCircle(x, y) {
      const ctx = this.ctx;
      const { radius, background, border } = this.config.points;
      ctx.restore();
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
      ctx.closePath();
      ctx.strokeStyle = border.color;
      ctx.lineWidth = border.width;
      ctx.stroke();
      ctx.fillStyle = background;
      ctx.fill();
    },
    calculateScatter() {
      const canvas = this.ctx.canvas;
      const scatterRange = canvas.height * 0.2 * this.config.scatter;
      return Math.random() * (scatterRange - 0) + 0;
    },
    // @see https://easings.net
    getEasing(value) {
      return 1 - Math.cos((value * Math.PI) / 2);
    },
  },
};
</script>
